<template>
    <div>
        <div class="d-flex justify-content-center w-100">
            <vs-tooltip bottom border>
                <vs-button icon circle @click="openNewFilter">
                    <img
                        src="@/assets/images/filter.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                </vs-button>

                <template #tooltip> Modificar filtros de búsqueda </template>
            </vs-tooltip>
        </div>

        <div class="d-flex flex-column mt-1">
            <div
                v-if="activeFilters.includes('keyword')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <vs-input
                    v-model="keyword"
                    border
                    shadow
                    type="text"
                    placeholder="Buscar por palabra clave .."
                    class="mr-2"
                    @blur="applyFilter"
                    @keyup.enter="onEnter"
                >
                    <template #icon> 🔎 </template>
                </vs-input>
            </div>

            <div
                v-if="activeFilters.includes('line')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <subline-selector
                    v-model="line"
                    :default-filters="{}"
                    enabled
                    @change="applyFilter"
                />
            </div>

            <div
                v-if="activeFilters.includes('brand')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <brand-selector v-model="brand" enabled @change="applyFilter" />
            </div>

            <div
                v-if="activeFilters.includes('barcode')"
                class="col-md-8 col-12 mx-auto mb-4"
            >
                <barcode-scanner-input
                    v-model="barcode"
                    enabled
                    @change="applyFilter"
                />
            </div>

            <div
                v-if="activeFilters.includes('active')"
                class="col-md-8 col-12 mx-auto mb-4 d-flex justify-content-center"
            >
                <span>Producto activo &nbsp;</span>
                <vs-checkbox v-model="active" warn @input="applyFilter" />
            </div>
        </div>

        <vs-dialog
            v-model="isOpenModal"
            scroll
            overflow-hidden
            auto-width
            @close="pushNewFilter"
        >
            <template #header>
                <div class="d-flex flex-column">
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3 class="text-center">Seleccione los filtros</h3>

                        <div>
                            <vs-button
                                :disabled="!filters.length"
                                icon
                                circle
                                @click="pushNewFilter"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <cards-selector v-model="filters" :base="baseFilters" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { productsFilters } from "@/common/fields/filters-query";

import CardsSelector from "@/components/utils/CardsSelector.vue";

export default {
    name: "ProductsFilters",
    components: {
        CardsSelector,
        BrandSelector: () => import("@/components/brands/BrandSelector.vue"),
        SublineSelector: () => import("@/components/lines/SublineSelector.vue"),
        BarcodeScannerInput: () =>
            import("@/components/utils/BarcodeScannerInput.vue")
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => ({}),
            type: Object,
            require: true
        },
        enabledFilters: {
            default: () => ["brand", "line", "keyword", "active", "barcode"],
            type: Array,
            require: true
        },
        defaultFilters: {
            default: () => [],
            type: Array,
            require: true
        }
    },
    emits: ["change", "enter"],
    data: () => ({
        activeFilters: [],
        isOpenModal: false,
        keyword: "",
        barcode: "",
        active: true,
        brand: { slug: "marca-blanca", name: "Marca Blanca" },
        line: { slug: "otros", name: "Otros" },
        filters: [],
        baseFilters: []
    }),
    watch: {
        enabledFilters(value) {
            this.setEnabledFilters(value);
        }
    },
    created() {
        this.setEnabledFilters(this.enabledFilters);
    },
    mounted() {
        this.setInput(this.itemsCurrent);
        this.activeFilters = [...this.defaultFilters];
        this.applyFilter();
    },
    methods: {
        openNewFilter() {
            this.isOpenModal = true;
        },
        pushNewFilter() {
            this.activeFilters = [...this.filters];
            this.isOpenModal = false;
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("change", {
                ...{
                    ...(this.activeFilters.includes("line")
                        ? { lineSlug: this.line.slug }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("brand")
                        ? { brandSlug: this.brand.slug }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("keyword")
                        ? { keyword: this.keyword }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("barcode")
                        ? { barcode: this.barcode }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("active")
                        ? { active: this.active }
                        : null)
                }
            });
        },
        onEnter() {
            this.applyFilter();
            this.$emit("enter");
        },
        setInput(value) {
            const filters = Object.keys(value);
            this.activeFilters = filters;
            this.filters = filters;
        },
        setEnabledFilters(value) {
            this.baseFilters = productsFilters.filter(
                (item) => value.includes(item.id) && item.show
            );
        }
    }
};
</script>
